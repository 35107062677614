export enum BASIC_FEATURE {
  MULTI_BRANCH = "feature_multi_branch",
  MULTI_CURRENCY = "feature_multi_currency",
  TAX_INCLUSIVE = "feature_tax_inclusive",
  APPROVAL = "feature_approval"
}

export enum ADDITIONAL_FEATURE {
  BRANCH = "branch",
  DEPARTMENT = "department",
  PROJECT = "project",
  ASSET_MANAGEMENT = "feature_asset_management"
}
