


































































































































































import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataMasterCurrency, ResponseListOfMasterCurrency } from "@/models/interface/settings.interface";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import Vue, { PropType } from "vue";
import { OPTION_BASIC_FEATURE, OPTION_ADDITIONAL_FEATURE } from "./constant/optionfeature.constant";
import { ADDITIONAL_FEATURE, BASIC_FEATURE } from "./enums/option-feature.enum";
import { COMPONENT_NAME } from "./Preferences.vue";
export interface IPrefFeature {
  basic: string[];
  additional: string[];
  currency: {
    feature_based_currency: string,
    feature_decimal_format: string,
    feature_decimal_point: string,
    feature_discount_before_after_tax: string
  };
  freight: string
}

export default Vue.extend({
  name: "PreferencesFeature",
  components: {
    CSelectProduct: () => import("@/components/shared/select-master-product/CSelectMasterProduct.vue")
  },
  props: {
    firstLoad: Boolean,
    dataFirstLoad: {} as PropType<IPrefFeature>
  },
  data() {
    return {
      demo: "",
      optBasicFeatures: OPTION_BASIC_FEATURE,
      optAdditionalFeatures: OPTION_ADDITIONAL_FEATURE,
      optDecimalFormat: [] as ResponseListMaster[],
      optDecimalNumber: [] as ResponseListMaster[],
      optionDiscount: [] as ResponseListMaster[],
      optBasedCurrency: [] as DataMasterCurrency[],
      // optDecimalFormat: OPTION_DECIMAL_FORMAT,
      // optDecimalNumber: OPTION_DECIMAL_NUMBER,
      adtIndeterminate: false,
      adtCheckAll: false,
      adtCheckedList: [],
      valPref: {
        basic: [] as string[],
        additional: [] as string[],
        currency: {
          feature_based_currency: "" as string,
          feature_decimal_format: "" as string,
          feature_decimal_point: "" as string,
          feature_discount_before_after_tax: "" as string
        },
        freight: "",
      } as IPrefFeature
    };
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        try {
          if (!this.firstLoad && newValue) {
            this.valPref = newValue;
            this.$emit("onChangeLoad", { name: COMPONENT_NAME.PREF_FEATURE, val: true });
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
  mounted () {
    const paramsDecimalFormat:RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "DECIMAL_FORMAT",
    };
    const paramsDecimalOption:RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "DECIMAL_POINT"
    };
    const paramsDiscountCalculation:RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "DISCOUNT_CALCULATION"
    };
    masterServices
      .listMaster(paramsDecimalFormat)
      .then((responseDecimalFormat) => {
        this.optDecimalFormat = responseDecimalFormat;
      });
    masterServices
      .listMaster(paramsDecimalOption)
      .then((responseDecimalOption) => {
        this.optDecimalNumber = responseDecimalOption;
      });
    masterServices
      .listMaster(paramsDiscountCalculation)
      .then((responseDiscount) => {
        this.optionDiscount = responseDiscount;
      });
    this.getCurrency();
  },
  methods: {
    generateLabelBasicFeature(key: string | BASIC_FEATURE): string {
      if (key === BASIC_FEATURE.MULTI_BRANCH) return this.$t("lbl_multi_branch").toString();
      else if (key === BASIC_FEATURE.MULTI_CURRENCY) return this.$t("lbl_multi_currency").toString();
      else if (key === BASIC_FEATURE.TAX_INCLUSIVE) return this.$t("lbl_tax_inclusive").toString();
      else if (key === BASIC_FEATURE.APPROVAL) return this.$t("lbl_approval").toString();
      return key;
    },
    generateLabelAdditionalFeature(key: string | ADDITIONAL_FEATURE): string {
      if (key === ADDITIONAL_FEATURE.BRANCH) return this.$t("lbl_branch").toString();
      else if (key === ADDITIONAL_FEATURE.DEPARTMENT) return this.$t("lbl_department").toString();
      else if (key === ADDITIONAL_FEATURE.PROJECT) return this.$t("lbl_project").toString();
      else if (key === ADDITIONAL_FEATURE.ASSET_MANAGEMENT) return this.$t("lbl_asset_management").toString();
      return key;
    },
    onChangeBasedCurrency(value): void {
      this.valPref.currency.feature_based_currency = value;
      this.$emit("onChange", {name: COMPONENT_NAME.PREF_FEATURE, val: this.valPref});
    },
    onChangeFreight({ id }): void {
      this.valPref.freight = id;
      this.$emit("onChange", {name: COMPONENT_NAME.PREF_FEATURE, val: this.valPref});
    },
    onChangeFormat(value): void {
      this.valPref.currency.feature_decimal_format = value;
      this.$emit("onChange", {name: COMPONENT_NAME.PREF_FEATURE, val: this.valPref});
    },
    onChangeOption(value): void {
      this.valPref.currency.feature_decimal_point = value;
      this.$emit("onChange", {name: COMPONENT_NAME.PREF_FEATURE, val: this.valPref});
    },
    onChangeBeforeAfterTax(value): void {
      this.valPref.currency.feature_discount_before_after_tax = value;
      this.$emit("onChange", {name: COMPONENT_NAME.PREF_FEATURE, val: this.valPref});
    },
    onChangeBasic(checkedList) {
      this.valPref.basic = checkedList;
      this.$emit("onChange", {name: COMPONENT_NAME.PREF_FEATURE, val: this.valPref});
    },
    onChangeAdditional(checkedList) {
      this.adtIndeterminate = !!checkedList.length && checkedList.length < this.optAdditionalFeatures[0].child.length;
      this.adtCheckAll = checkedList.length === this.optAdditionalFeatures[0].child.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        adtCheckedList: e.target.checked ? this.optAdditionalFeatures[0].child.map(x => x.value) : [],
        adtIndeterminate: false,
        adtCheckAll: e.target.checked,
      });
    },
    getListOfMasterCurrency(param: RequestQueryParamsModel, path = ""): Promise<ResponseListOfMasterCurrency> {
      return settingsServices.listOfMasterCurrency(param, path);
    },
    getCurrency(): void {
      const param: RequestQueryParamsModel = {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
      };
      this.getListOfMasterCurrency(param)
        .then((res: ResponseListOfMasterCurrency) => {
          this.optBasedCurrency = res.data;
        });
    }
  }
});
