import { OptionModel } from "@/models/interface/common.interface";
import { BASIC_FEATURE, ADDITIONAL_FEATURE } from "../enums/option-feature.enum";

export const OPTION_BASIC_FEATURE = [
    {
        value: BASIC_FEATURE.MULTI_BRANCH
    },
    {
        value: BASIC_FEATURE.MULTI_CURRENCY
    },
    {
        value: BASIC_FEATURE.TAX_INCLUSIVE
    },
    {
        value: BASIC_FEATURE.APPROVAL
    },
];

export const OPTION_ADDITIONAL_FEATURE: OptionModel[] = [
    {
        label: 'lbl_budgeting',
        value: 'budgeting',
        child: [
            {
                value: ADDITIONAL_FEATURE.BRANCH
            },
            {
                value: ADDITIONAL_FEATURE.DEPARTMENT
            },
            {
                value: ADDITIONAL_FEATURE.PROJECT
            },
            {
                value: ADDITIONAL_FEATURE.ASSET_MANAGEMENT
            },
        ]
    }
];

export const OPTION_DECIMAL_FORMAT: OptionModel[] = [
    {
        label: 'Indonesia (9.999,9)',
        value: 'id'
    },
    {
        label: 'Foreign (9,999.9)',
        value: 'other'
    }
];

export const OPTION_DECIMAL_NUMBER: OptionModel[] = [
    {
        label: '0,9',
        value: '1'
    },
    {
        label: '0,99',
        value: '2'
    },
    {
        label: '0,999',
        value: '3'
    }
];
