var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 12, xs: 24, sm: 24, md: 12 } },
            [
              _c("a-card", { attrs: { bordered: false } }, [
                _c("p", { staticClass: "text-subtitle-1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_basic_feature")) + " ")
                ]),
                _c(
                  "div",
                  [
                    _c("a-checkbox-group", {
                      staticClass: "check-basic",
                      attrs: { options: _vm.optBasicFeatures },
                      on: { change: _vm.onChangeBasic },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function(ref) {
                            var value = ref.value
                            return _c("span", {}, [
                              _vm._v(
                                _vm._s(_vm.generateLabelBasicFeature(value))
                              )
                            ])
                          }
                        }
                      ]),
                      model: {
                        value: _vm.valPref.basic,
                        callback: function($$v) {
                          _vm.$set(_vm.valPref, "basic", $$v)
                        },
                        expression: "valPref.basic"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 7 } },
            [
              _c("a-card", { attrs: { bordered: false } }, [
                _c("p", { staticClass: "text-subtitle-1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_freight")) + " ")
                ]),
                _c(
                  "div",
                  [
                    _c("CSelectProduct", {
                      attrs: { "prop-search-by": "type~SERVICE" },
                      on: {
                        "on-select": function(ref) {
                          var meta = ref.meta

                          return _vm.onChangeFreight(meta)
                        }
                      },
                      model: {
                        value: _vm.valPref.freight,
                        callback: function($$v) {
                          _vm.$set(_vm.valPref, "freight", $$v)
                        },
                        expression: "valPref.freight"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                { attrs: { bordered: false } },
                [
                  _c("p", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_currency_format")) + " ")
                  ]),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 4, xs: 8, sm: 8, md: 4 } }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("lbl_based_currency")))])
                      ]),
                      _c(
                        "a-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled: "",
                                placeholder: _vm.$t("lbl_select_here")
                              },
                              on: { change: _vm.onChangeBasedCurrency },
                              model: {
                                value:
                                  _vm.valPref.currency.feature_based_currency,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.valPref.currency,
                                    "feature_based_currency",
                                    $$v
                                  )
                                },
                                expression:
                                  "valPref.currency.feature_based_currency"
                              }
                            },
                            _vm._l(_vm.optBasedCurrency, function(opt) {
                              return _c(
                                "a-select-option",
                                { key: opt.id, attrs: { value: opt.id } },
                                [_vm._v(" " + _vm._s(opt.currencyCode) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 4, xs: 8, sm: 8, md: 4 } }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("lbl_decimal_format")))])
                      ]),
                      _c(
                        "a-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: _vm.$t("lbl_select_here") },
                              on: { change: _vm.onChangeFormat },
                              model: {
                                value:
                                  _vm.valPref.currency.feature_decimal_format,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.valPref.currency,
                                    "feature_decimal_format",
                                    $$v
                                  )
                                },
                                expression:
                                  "valPref.currency.feature_decimal_format"
                              }
                            },
                            _vm._l(_vm.optDecimalFormat, function(opt) {
                              return _c(
                                "a-select-option",
                                { key: opt.id, attrs: { value: opt.id } },
                                [_vm._v(" " + _vm._s(opt.value) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 4, xs: 8, sm: 8, md: 4 } }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("lbl_decimal_option")))])
                      ]),
                      _c(
                        "a-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: _vm.$t("lbl_select_here") },
                              on: { change: _vm.onChangeOption },
                              model: {
                                value:
                                  _vm.valPref.currency.feature_decimal_point,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.valPref.currency,
                                    "feature_decimal_point",
                                    $$v
                                  )
                                },
                                expression:
                                  "valPref.currency.feature_decimal_point"
                              }
                            },
                            _vm._l(_vm.optDecimalNumber, function(opt) {
                              return _c(
                                "a-select-option",
                                { key: opt.id, attrs: { value: opt.value } },
                                [_vm._v(" " + _vm._s(opt.value) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 4, xs: 8, sm: 8, md: 4 } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("lbl_discount_before_after_tax"))
                          )
                        ])
                      ]),
                      _c(
                        "a-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: _vm.$t("lbl_select_here") },
                              on: { change: _vm.onChangeBeforeAfterTax },
                              model: {
                                value:
                                  _vm.valPref.currency
                                    .feature_discount_before_after_tax,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.valPref.currency,
                                    "feature_discount_before_after_tax",
                                    $$v
                                  )
                                },
                                expression:
                                  "valPref.currency.feature_discount_before_after_tax"
                              }
                            },
                            _vm._l(_vm.optionDiscount, function(opt) {
                              return _c(
                                "a-select-option",
                                { key: opt.id, attrs: { value: opt.id } },
                                [_vm._v(" " + _vm._s(opt.value) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }